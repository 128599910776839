<template>
  <v-simple-table
    fixed-header
    height="100%"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Role
          </th>
          <th class="text-left">
            Email
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in item.eval_pending"
          :key="item"
        >
          <td>Evaluation Committee</td>
          <td>{{ item }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>

export default{
    props: ["item"],
}
</script>
