<template>
    <v-expansion-panels focusable>
        <v-expansion-panel
            v-for="(comments,index) in disapproveComments"
            :key="index"
        >
            <v-expansion-panel-header>{{ comments.name }}</v-expansion-panel-header>
            <v-expansion-panel-content class="mt-3 mx-2">
                {{ comments.comment }}
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex'


export default{
    computed:{
        ...mapGetters({
            disapproveComments: 'salesProspecting/disapproveComments'
        })
    }
}
</script>
