<template>
    <v-expansion-panels focusable>
        <v-expansion-panel
            v-for="(item,index) in items"
            :key="index"
        >
            <v-expansion-panel-header>{{ item.det_type }}</v-expansion-panel-header>
            <v-expansion-panel-content class="mt-3 mx-2">
                {{ item.comment }} <br> &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; - {{ item.name }}
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex'


export default{
    data () {
        return {
            comment: '',
            name: '',
            items: []
        }
    },
    computed:{
        ...mapGetters({
            encoder_returned_comments: 'salesProspecting/encoder_returned_comments'
        })
    },
    async created () {
        this.encoder_returned_comments.map(value => {
            value.returned_comment_from_det_encoder.map(value => {
                console.log(value)
                if (value.status == 'pending'){
                    this.items.push(value)
                    console.log(value)
                }
            })
        })
    },
}
</script>
