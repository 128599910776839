<template>
    <v-expansion-panels focusable>
        <v-expansion-panel
            v-for="(item,index) in compliance_approved_comments"
            :key="index"
        >
            <v-expansion-panel-header>{{ item.det_type }}</v-expansion-panel-header>
            <v-expansion-panel-content class="mt-3 mx-2">
                {{ item.approved_comment[0].comment }}<br> &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; &emsp; - {{ item.approved_comment[0].name }}
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex'


export default{
    computed:{
        ...mapGetters({
            compliance_approved_comments: 'salesProspecting/compliance_approved_comments'
        })
    },
}
</script>
