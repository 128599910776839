<template>
    <v-expansion-panels focusable>
        <v-expansion-panel
            v-for="(comments,index) in comment "
            :key="index"
        >
            <v-expansion-panel-header >{{ comments.name }}</v-expansion-panel-header>
            <v-expansion-panel-content class="mt-3 mx-2">
                {{ comments.comment }}
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mapGetters } from 'vuex'


export default{
    data() {
        return	{
            comment: []
        };
	},
    computed:{
        ...mapGetters({
            setFinalComments: 'salesProspecting/setFinalComments'
        })
    },
    mounted(){
        if (this.setFinalComments != null){
            for(const item of this.setFinalComments){
                if(item.status == "pending" ){
                    this.comment.push(item)
                    break;
                }
            }
        console.log(this.comment)
        }

    }
}
</script>
