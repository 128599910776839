<template>
    <v-container class="px-12">
        <v-row>
            <v-col :cols="12" :md="2" class="mt-5">
                <div class="container">
                    <div class="outer">
                        <v-img
                            class="outer"
                            lazy-src="https://picsum.photos/id/11/10/6"
                            :src="blobUrl"
                        ></v-img>
                        <div class="inner">
                            <input type="file" ref="file" style="display: none" accept="image/*" @change="getImage"/>
                            <v-btn
                                v-if="currUser.name == this.profileEncoder"
                                fab color="primary" @click="$refs.file.click()">
                                <v-icon>mdi-cloud-upload-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col :cols="12" :md="3">
                <v-list>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title><small>ENTITY NAME</small></v-list-item-title>
                            <v-list-item-subtitle><b>{{primaryDetails.legal_entity_name}}</b></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title><small>CORPORATE EMAIL</small></v-list-item-title>
                            <v-list-item-subtitle><v-icon color="primary">mdi-email</v-icon><b> {{ primaryContactInfo ? primaryContactInfo.email_address: '' }}</b></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title><small>CONTACT</small></v-list-item-title>
                            <v-list-item-subtitle><v-icon color="primary">mdi-cellphone</v-icon><b> {{ primaryContactInfo ? primaryContactInfo.cellphone: '' }}</b></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
             </v-col>
            <v-col :cols="12" :md="3">
                <v-list>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title><small>PROFILE</small></v-list-item-title>
                            <v-list-item-subtitle><b>{{ primaryDetails.status }}</b></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title><small>ACCOUNT OWNER</small></v-list-item-title>
                            <v-list-item-subtitle><b>{{ primaryDetails.owner }}</b></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title><small>DATE CREATED</small></v-list-item-title>
                            <v-list-item-subtitle><b>{{ formatDate(primaryDetails.date_created) }}</b></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-tabs
                    v-model="tab"
                    >
                    <v-tab>Overview</v-tab>
                    <v-tab v-if="primaryDetails.status === 'Initial Evaluation Committee Approval Pending'">Pending Approvers</v-tab>
                    <v-tab v-if="currUser.name == this.profileEncoder">Files Uploaded</v-tab>
                    <!-- <v-tab>History/Activities</v-tab> -->
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card flat>
                            <v-container>
                                <v-row>
                                    <v-col :cols="5" class="mt-5">
                                        <v-expansion-panels>
                                            <v-expansion-panel>
                                                <v-expansion-panel-header>ACCOUNT INFORMATION</v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row>
                                                            <v-col :cols="12">
                                                                Account Name: <b>{{primaryDetails.sp_number}}</b>
                                                            </v-col>
                                                            <v-col :cols="12">
                                                                Legal Entity Name: <b>{{primaryDetails.legal_entity_name}}</b>
                                                            </v-col>
                                                            <v-col :cols="12">
                                                                Trade Name: <b>{{primaryDetails.trade_name}}</b>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                            </v-expansion-panel>
                                            <v-expansion-panel v-if="primaryDetails.is_existing == 0">
                                                <v-expansion-panel-header>SALES PRESENTATION INFORMATION</v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <v-row>
                                                            <v-col :cols="12">
                                                                Date of Presentation: <b>{{primaryDetails.date_presentation}}</b>
                                                            </v-col>
                                                            <v-col :cols="12">
                                                                Documents Presented:<br>
                                                                <v-list>
                                                                    <v-list-item v-for="(item, index) in primaryDetails.documents_presented" :key="index">
                                                                        <b>{{item}}</b>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-col>
                                                        </v-row>
                                                    </v-expansion-panel-content>
                                            </v-expansion-panel>
                                            <!-- <v-expansion-panel v-if="(primaryDetails.status != 'Existing (Validation Approval Pending)') && (primaryDetails.status != 'Initial Existing Complete') && (primaryDetails.status != 'Existing Complete')"> -->
                                            <v-expansion-panel v-if="primaryDetails.is_existing == 0">
                                                <v-expansion-panel-header>SCORE CARD</v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <b>FINAL RATING: {{computeFinalRating()}}%</b> 
                                                    </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>

                                        <!-- <h4 class="mt-5 mb-2" v-if="(primaryDetails.status != 'Existing (Validation Approval Pending)') && (primaryDetails.status != 'Initial Existing Complete') && (primaryDetails.status != 'Existing Complete')">APPROVERS COMMENTS</h4> -->
                                        <h4 class="mt-5 mb-2" v-if="primaryDetails.is_existing == 0 && setComments != null && setComments != 0">INITIAL APPROVER COMMENTS</h4>
                                        <Comments/>

                                        <h4 class="mt-5 mb-2" v-if="setFinalComments != null && commentStatus">RETURNED COMMENTS</h4>
                                        <FinalComments/>

                                        <h4 class="mt-5 mb-2" v-if="returnedEvaComments != null && returnedEvalCommentStatus">EVALUATION RETURNED COMMENTS</h4>
                                        <EvalReturnedComments/>

                                        <h4 class="mt-5 mb-2" v-if="primaryDetails.is_existing == 0 && disapproveComments != null && disapproveComments != 0">DISAPPROVE PRESIDENT COMMENTS</h4>
                                        <DisapproveComments/>

                                        <h4 class="mt-5 mb-2" v-if="showComplianceReturnComments" >COMPLIANCE RETURNED COMMENTS (DET)</h4>
                                        <DetComplianceReturnedComment/>

                                        <h4 class="mt-5 mb-2" v-if="showDetReturnComments" >DET ENCODER RETURNED COMMENTS (DET)</h4>
                                        <DetEncoderReturnedComment/>

                                        <h4 class="mt-5 mb-2" v-if="showComplianceApprovedComments" >COMPLIANCE APPROVED COMMENTS (DET)</h4>
                                        <DetComplianceApprovedComments/>
                                        
                                    </v-col>

                                    <v-col :cols="5" v-if="allData.profile == 'complete'">
                                        <v-list>
                                            <v-list-item v-for="(item, index) in pendingActions" :key="index">
                                                <v-list-item-content>
                                                <v-list-item-title v-text="item.text"></v-list-item-title>
                                                <v-progress-linear
                                                :color="item.value.color"
                                                height="20"
                                                :value="item.value.number"
                                                >
                                                <template>
                                                    <strong>{{ item.value.number }}%</strong>
                                                </template>
                                                </v-progress-linear>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>

                                    <v-col :cols="2" class="py-12" v-if="allData.profile == 'complete'">
                                        <v-progress-circular
                                        :rotate="-90"
                                        :size="150"
                                        :width="15"
                                        :value="displayTotal"
                                        color="#1565c0"
                                        >
                                        {{displayTotal}}%
                                        <br>Overall
                                        </v-progress-circular>
                                    </v-col>

                                </v-row>
                            </v-container>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item v-if="primaryDetails.status === 'Initial Evaluation Committee Approval Pending'">
                        <v-card flat class="mt-5">
                            <h3 class="my-3">Pending Approvers</h3>
                            <PendingApprovers :item="pendingApprovers"> </PendingApprovers>
                        </v-card>
                        <v-card flat class="mt-5" v-if="pendingApprovers.eval_approved.length != 0">
                            <h3 class="my-3">Approved Approvers</h3>
                            <ApprovedApprovers :item="pendingApprovers"> </ApprovedApprovers>
                        </v-card>
                    </v-tab-item>

                    <v-tab-item>
                        <h4 class="mt-5" v-if="documentsUploaded">Uploading of Documents</h4>
                        <v-card flat class="mt-5" v-if="documentsUploaded">
                            <UploadedFiles/>
                        </v-card>
                        <h4 class="mt-5" v-if="branchUploaded">Branch</h4>
                        <v-card flat class="mt-5" v-if="branchUploaded">
                            <BranchFiles/>
                        </v-card>

                        <!-- <v-pagination
                        class="mt-3"
                        @input="changePage"
                        v-model="page"
                        :total-visible="5"
                        :length="branchPage.length"
                        ></v-pagination> -->

                        <h4 class="mt-5" v-if="ownerUploaded">Owner</h4>
                        <v-card flat class="mt-5" v-if="ownerUploaded">
                            <OwnerAmla/>
                        </v-card>
                        <h4 class="mt-5" v-if="primaryDetails.status != 'Initial Evaluation Committee Approval Pending' && sp_status != 'existing' && sp_status != 'Archived' && sp_status != 'pending'">BSP</h4>
                        <v-card flat class="mt-5" v-if="primaryDetails.status != 'Initial Evaluation Committee Approval Pending' && sp_status != 'existing' && sp_status != 'Archived' && sp_status != 'pending'">
                            <BSPLicenseProfile/>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
               
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import { parseISO, format } from 'date-fns';
import Comments from '../components/modules/salesProspecting/Comments.vue';
import UploadedFiles from '../components/modules/salesProspecting/UploadedFiles.vue';
import BranchFiles from '../components/modules/salesProspecting/BranchFiles.vue';
import OwnerAmla from '../components/modules/salesProspecting/OwnerAmla.vue';
import PendingApprovers from '../components/modules/salesProspecting/PendingApprovers.vue';
import ApprovedApprovers from '../components/modules/salesProspecting/ApprovedApprovers.vue';
import BSPLicenseProfile from '../components/modules/salesProspecting/BSPLicenseProfile.vue';
import FinalComments from '../components/modules/salesProspecting/FinalComments.vue';
import EvalReturnedComments from '../components/modules/salesProspecting/EvalReturnedComments.vue';
import DisapproveComments from '../components/modules/salesProspecting/DisapproveComments.vue';
import DetComplianceReturnedComment from '../components/modules/salesProspecting/DetComplianceReturnedComment.vue';
import DetEncoderReturnedComment from '../components/modules/salesProspecting/DetEncoderReturnedComment.vue';
import DetComplianceApprovedComments from '../components/modules/salesProspecting/DetComplianceApprovedComments.vue';
import jsonToFormData from 'json-form-data';
import _ from 'lodash';

export default {
    name: 'Users',
    components: {
		Comments: Comments,
        UploadedFiles:UploadedFiles,
        BranchFiles: BranchFiles,
        OwnerAmla: OwnerAmla,
        BSPLicenseProfile: BSPLicenseProfile,
        FinalComments: FinalComments,
        EvalReturnedComments: EvalReturnedComments,
        DisapproveComments: DisapproveComments,
        DetComplianceReturnedComment: DetComplianceReturnedComment,
        DetEncoderReturnedComment: DetEncoderReturnedComment,
        DetComplianceApprovedComments: DetComplianceApprovedComments,
        PendingApprovers: PendingApprovers,
        ApprovedApprovers: ApprovedApprovers,
	},
    data () {
        return {
            existingStatus: ['Initial Existing Complete', 'Existing Complete', 'Existing (Validation Approval Pending)'],
            tab: null,
            pendingActions: [],
            primaryDetails: null,
            otherPrimaryDetails: null,
            primaryContactInfo: null,
            commentStatus: null,
            returnedEvalCommentStatus: null,
            ownerUploaded: null,
            branchUploaded: null,
            documentsUploaded: null,
            bspUploaded: null,
            showComplianceReturnComments: false,
            showDetReturnComments: false,
            showComplianceApprovedComments: false,
            sp_status: '',
            pendingApprovers: null,
            allData: '',
            finalData: '',
            displayTotal: '',
            profilePic: {
                profile_picture: {
                    file: null,
                },
            },
            blobUrl: '',
            profileEncoder: '',
            page: 1,
            branchPage: []
        }
    },
    computed: {
        ...mapGetters({
            currUser: 'auth/currUser',
            renewableUploadedFiles: 'salesProspecting/renewableUploadedFiles',
            setFinalComments: 'salesProspecting/setFinalComments',
            returnedEvaComments: 'salesProspecting/returnedEvaComments',
            setComments: 'salesProspecting/setComments',
            disapproveComments: 'salesProspecting/disapproveComments',
            compliance_returned_comments: 'salesProspecting/compliance_returned_comments',
        })
    },
    watch: {
        pendingActions (newPen) {
            if (newPen) {
                this.computeTotalPercent()
            }
        }
    },
    async created () {
        const resSP = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
        this.allData = resSP.data.result[0]
        this.checkIcon()
        this.assignData(resSP.data.result[0])
        this.countBranchPage(resSP.data.result[0].sp_branch)
        this.decodeBranches(resSP.data.result[0].sp_branch.data)

        if(resSP.data.result[0].sp_primary_details.profile_picture){
            this.getProfileImage(resSP.data.result[0].sp_primary_details.profile_picture)
        } else {
            this.blobUrl = 'https://picsum.photos/id/11/500/300'
        }
        
    },
    methods: {
        async reload(){
            const resSP = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
            this.getProfileImage(resSP.data.result[0].sp_primary_details.profile_picture)
        },
        countBranchPage(e){
			this.page = 1
			this.branchPage = []
			if(e){
				let number = e.last_page
				for (let i = 1; i <= number; i++) {
					this.branchPage.push(i)
				}
			}
		},
		async changePage(e){
			const payload = {
                id : this.$route.params.id,
                page: e
            }
			const res = await this.$store.dispatch('salesProspecting/changePageBranch', {payload})
			if(res.status === 200) {
				this.decodeBranches(res.data.result[0].sp_branch.data)
			}
		},
        decodeBranches (resBranches) {
			let expiryFile = {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            }
			let nonExpiryFile = {
                file: null
            }
			resBranches.forEach(function (e) {
				// console.log(e.business_permit)
				e.branch_id = e.id
				e.businessPermitUploaded = JSON.parse(e.business_permit)
				e.justificationUploaded = JSON.parse(e.justification)
				// console.log(e.businessPermitUploaded)
				e.operating_hours = JSON.parse(e.operating_hours)
				e.operating_hours.forEach(function (g) {
					g.isClosed = parseInt(g.isClosed)
				})
				e.coordinates = JSON.parse(e.coordinates)
				e.business_permit = _.clone(expiryFile)
				e.justification = _.clone(nonExpiryFile)
				e.fla.forEach(function (f) {
					f.amlaUploaded  = JSON.parse(f.amla)
					f.drugTestUploaded = JSON.parse(f.drug_test)
					f.nbiUploaded = JSON.parse(f.nbi)
					f.amla = _.clone(expiryFile)
					f.drug_test = _.clone(expiryFile)
					f.nbi = _.clone(nonExpiryFile)
				})
			})
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_BRANCHES', resBranches)
		},
        async getProfileImage(data){
            let newArr = JSON.parse(data)
            var latestObj = newArr.reduce((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? a : b;
            })
            this.profile_link = latestObj.url
            const payload = {
				file: latestObj.url,
			}
            const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
            var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			} else if (extension === 'xlsm') {
                console.log()
				blob = new Blob([file], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12' })
			}
			this.blobUrl = URL.createObjectURL(blob)
        },
        getImage(event) {
            this.profilePic.profile_picture.file = event.target.files[0]
            this.callUploadeProfile()
        },
        async callUploadeProfile(){
            var options = {
				initialFormData: new FormData(),
				howLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			}
			var convertedFormData = jsonToFormData(this.profilePic, options)
			const payload = {id: this.$route.params.id, payload: convertedFormData}
            const res = await this.$store.dispatch('salesProspecting/doUploadProfile', {payload})
            if (res.status == 200){
                this.reload()
            }
        },
        computeTotalPercent () {
            var newItem = this.allData.completion_rate.total_completion_rate.replace(/%/g, '')
            let totalPercent = Math.round(newItem)
            this.displayTotal =  totalPercent
        },
        checkIcon () {
            this.pendingActions.push({text: 'Uploaded Files', value: this.computePercent(this.allData.completion_rate.upload_rate)})
            this.pendingActions.push({text: 'Primary Business Address', value: this.computePercent(this.allData.completion_rate.primary_business_address_rate)})
            this.pendingActions.push({text: 'Primary Contact Information', value: this.computePercent(this.allData.completion_rate.primary_contact_information_rate)})
            this.pendingActions.push({text: 'Compliance Officer/Delegate', value: this.computePercent(this.allData.completion_rate.compliance_officer_delegate_rate)})
            this.pendingActions.push({text: 'Business Information', value: this.computePercent(this.allData.completion_rate.business_information_rate)})
            this.pendingActions.push({text: 'Branch', value: this.computePercent(this.allData.completion_rate.sp_branch_rate)})
            this.pendingActions.push({text: 'Owner', value: this.computePercent(this.allData.completion_rate.sp_owner_rate)})
            if (this.allData.sp_other_primary_details){
                if (this.allData.sp_other_primary_details.type != 'Single Proprietor'){
                    this.pendingActions.push({text: 'Board of Directors', value: this.computePercent(this.allData.completion_rate.sp_bod_rate)})
                }
            }
            this.pendingActions.push({text: 'BSP License', value: this.computePercent(this.allData.completion_rate.bsp_license_rate)})
        },
        computePercent(item){
            if (item) {
                var items = item.replace(/%/g, '')
                let newItem = Math.round(items)
                var color = ''
                
                if (newItem == 100) {
                    // success
                    color = '#4caf50'
                } else if (newItem > 19 && newItem <= 99) {
                    // warning
                    color = '#ff9800'
                } else {
                    // error
                    color = '#ef5350'
                }
                return {number: newItem, color: color}
            } else {
                return {number: 0, color: 'red'}
            }
        },
        formatDate (date) {
            return format(parseISO(date), 'yyyy-MM-dd')
        },
        assignData (data) {
            this.profileEncoder = data.created_by
            this.sp_status = data.status.toString()
            if (data.sp_owner.length != 0){
                this.ownerUploaded = true
            } else if (data.sp_owner.length == 0){
                this.ownerUploaded = false
            }
            if (data.sp_branch.length != 0){
                this.branchUploaded = true
            } else if (data.sp_branch.length == 0){
                this.branchUploaded = false
            }
            if (data.sp_location.length != 0){
                this.documentsUploaded = true
            } else if (data.sp_location.length == 0){
                this.documentsUploaded = false
            }
            if (data.bsp_license != null){
                this.bspUploaded = true
            } else if (data.bsp_license == null){
                this.bspUploaded = false
            }
            this.payload = JSON.parse(data.initial_comment)
            this.$store.commit('salesProspecting/SET_VIEW_COMMENTS', this.payload)

            this.disapproveComment = JSON.parse(data.initial_disapproved_comment)
            this.$store.commit('salesProspecting/SET_DISAPPROVE_COMMENTS', this.disapproveComment)

            this.finalPayload = JSON.parse(data.returned_comment)
            this.$store.commit('salesProspecting/SET_VIEW_FINAL_COMMENTS', this.finalPayload)
            if (this.setFinalComments != null){
                this.setFinalComments.map(value => {
                if (value.status == 'complete'){
                    this.commentStatus = false
                }
                if (value.status == 'pending'){
                    this.commentStatus = true
                }
                })
            }
            this.evalReturnedComments = JSON.parse(data.initial_returned_comment)
            this.$store.commit('salesProspecting/SET_RETURNED_EVA_COMMENTS', this.evalReturnedComments)
            if (this.returnedEvaComments != null){
                this.returnedEvaComments.map(value => {
                if (value.status == 'complete'){
                    this.returnedEvalCommentStatus = false
                }
                if (value.status == 'pending'){
                    this.returnedEvalCommentStatus = true
                }
                })
            }
            
            if (data.det_approval_comment != null){
                data.det_approval_comment.map(item => {
                    item.approved_comment = JSON.parse(item.approved_comment)
                    item.returned_comment_from_compliance = JSON.parse(item.returned_comment_from_compliance)
                    item.returned_comment_from_det_encoder = JSON.parse(item.returned_comment_from_det_encoder)
            // DET Approved
                    if (item.det_status == 'DET Compliance (Returned to DET Encoder)' || item.det_status == 'DET Compliance (Returned to Account Manager)'){
                        this.$store.commit('salesProspecting/SET_COMPLIANCE_RETURNED_COMMENTS', item)
                        this.showComplianceReturnComments = true
                    }

                    if (item.det_status === 'DET Encoder (Returned to Account Manager)'){
                        this.$store.commit('salesProspecting/SET_ENCODER_RETURNED_COMMENTS', item)
                        this.showDetReturnComments = true
                    }
                    
                    if (item.det_status === 'DET Approved'){
                        this.$store.commit('salesProspecting/SET_COMPLIANCE_APPROVED_COMMENTS', item)
                        this.showComplianceApprovedComments = true
                    }

				})
            }
			this.status = data.status
			this.primaryDetails = data.sp_primary_details
            this.pendingApprovers = data.evaluation_status
            if(data.status != 'existing'){
                this.primaryDetails.scorecard = JSON.parse(data.sp_primary_details.scorecard)
            }
			this.primaryDetails.documents_presented = JSON.parse(data.sp_primary_details.documents_presented)
			this.primaryDetails.upload_loi = []
			this.primaryDetails.upload_picture = []
			this.primaryDetails.upload_supp_docs = []
            this.primaryDetails.sp_number = data.prospective_number
            this.primaryDetails.owner = data.created_by
            this.primaryDetails.date_created = data.created_at
            this.primaryDetails.status = data.status
            this.primaryDetails.is_existing = data.is_existing
			if (data.sp_other_primary_details){
				data.sp_other_primary_details.list_of_non_ex_remco = JSON.parse(data.sp_other_primary_details.list_of_non_ex_remco)
				data.sp_other_primary_details.list_of_avp_products = JSON.parse(data.sp_other_primary_details.list_of_avp_products)
				data.sp_other_primary_details.business_information = JSON.parse(data.sp_other_primary_details.business_information)
				data.sp_other_primary_details.business_type_of_sub_agents = JSON.parse(data.sp_other_primary_details.business_type_of_sub_agents )
				data.sp_other_primary_details.top_reasons_for_WU = JSON.parse(data.sp_other_primary_details.top_reasons_for_WU)
				data.sp_other_primary_details.typical_customers = JSON.parse(data.sp_other_primary_details.typical_customers)
				this.businessType = data.sp_other_primary_details.type				
				this.otherPrimaryDetails = data.sp_other_primary_details
			}
            if (data.sp_primary_contact_information) {
                this.primaryContactInfo = data.sp_primary_contact_information
            }
		},
        computeFinalRating(){
			if(this.primaryDetails.scorecard){
				var total = 0
				this.primaryDetails.scorecard.forEach(function(e){
					e.criteria.forEach(function(f){
						total += (parseInt(f.score) * (f.weight / 100))
					})
				})
				if(isNaN(total)){
					return ''
				}
				return ((total / 4) * 100).toFixed(2)
			}
		},
    }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
}

.outer {
  width: 100% !important;
  height: 100% !important;
  max-width: 150px !important; /* any size */
  max-height: 150px !important; /* any size */
  margin: auto;
  border-radius: 100%;
  position: relative;
  }
  
.inner {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}
.inputfile {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
}
.inputfile + label {
    font-size: 1.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;
    width: 50px;
    height: 50px;
    pointer-events: none;
    cursor: pointer;
    line-height: 50px;
    text-align: center;
}
.inputfile + label svg {
    fill: #fff;
}
</style>