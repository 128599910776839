<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header>Upload Documents</v-expansion-panel-header>
                <v-expansion-panel-content v-if="renewableUploadedFiles.type == 'Single Proprietor'">
                    <v-row class="mx-3">
                        <v-col :cols="2">
                            <b>File</b>
                        </v-col>
                        <v-col :cols="3" align="center">
                            <b>Expiry Date</b>
                        </v-col>
                    </v-row>
                    
                    <v-row class="mx-3"
                    v-if="renewableUploadedFiles.dti"
                    >
                        <v-col :cols="2" class="red--text"
                            v-if="renewableUploadedFiles.dti.is_primary || renewableUploadedFiles.dti.is_secondary"
                        >
                            <b>DTI</b>
                        </v-col>
                        <v-col :cols="2"
                            v-else
                        >
                            DTI
                        </v-col>

                        <v-col :cols="3" align="center" class="red--text"
                            v-if="renewableUploadedFiles.dti.is_primary || renewableUploadedFiles.dti.is_secondary"
                        >
                            <b>{{ renewableUploadedFiles.dti.date_expiry }}</b>
                        </v-col>
                        <v-col :cols="3" align="center"
                            v-else
                        >
                            {{ renewableUploadedFiles.dti.date_expiry }}
                        </v-col>

                        <v-col :cols="1">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.dti)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col :cols="2"
                            v-if="(renewableUploadedFiles.dti.is_primary || renewableUploadedFiles.dti.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.single_proprietor.dti.file"
                                label="Update"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                        <v-col :cols="2"
                            v-if="(renewableUploadedFiles.dti.is_primary || renewableUploadedFiles.dti.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.single_proprietor.dti.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.single_proprietor.dti.date_issued"
                                        label="Issued"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.single_proprietor.dti.file  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.single_proprietor.dti.date_issued"
                                    @input="documents.single_proprietor.dti.menuIssued = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :cols="2"
                                v-if="(renewableUploadedFiles.dti.is_primary || renewableUploadedFiles.dti.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.single_proprietor.dti.menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.single_proprietor.dti.date_expiry"
                                        label="Expiry"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.single_proprietor.dti.date_issued  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.single_proprietor.dti.date_expiry"
                                    @input="documents.single_proprietor.dti.menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row class="mx-3 mt-3"
                    v-if="renewableUploadedFiles.business_permit"
                    >
                        <v-col :cols="2" class="red--text"
                            v-if="renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary"
                        >
                            <b>Business Permit</b>
                        </v-col>
                        <v-col :cols="2"
                            v-else
                        >
                            Business Permit
                        </v-col>

                        <v-col :cols="3" align="center" class="red--text"
                            v-if="renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary"
                        >
                            <b>{{ renewableUploadedFiles.business_permit.date_expiry }}</b>
                        </v-col>
                        <v-col :cols="3" align="center"
                            v-else
                        >
                            {{ renewableUploadedFiles.business_permit.date_expiry }}
                        </v-col>

                        <v-col :cols="1">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.business_permit)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col :cols="2"
                            v-if="(renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.single_proprietor.business_permit.file"
                                label="Update"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                        <v-col :cols="2"
                            v-if="(renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.single_proprietor.business_permit.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.single_proprietor.business_permit.date_issued"
                                        label="Issued"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.single_proprietor.business_permit.file  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.single_proprietor.business_permit.date_issued"
                                    @input="documents.single_proprietor.business_permit.menuIssued = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :cols="2"
                                v-if="(renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.single_proprietor.business_permit.menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.single_proprietor.business_permit.date_expiry"
                                        label="Expiry"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.single_proprietor.business_permit.date_issued  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.single_proprietor.business_permit.date_expiry"
                                    @input="documents.single_proprietor.business_permit.menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row class="mx-3">
                        
                        <v-col :cols="2" v-if="!renewableUploadedFiles.amla_cor">
                            AMLA COR
                        </v-col>
                        <v-col :cols="2" class="red--text" v-else-if="renewableUploadedFiles.amla_cor.is_primary || renewableUploadedFiles.amla_cor.is_secondary">
                            <b>AMLA COR</b>
                        </v-col>
                        <v-col :cols="2" v-else>
                            AMLA COR
                        </v-col>

                        <v-col :cols="3" align="center" v-if="!renewableUploadedFiles.amla_cor">
                            No File
                        </v-col>
                        <v-col :cols="3" align="center" class="red--text" v-else-if="renewableUploadedFiles.amla_cor.is_primary || renewableUploadedFiles.amla_cor.is_secondary">
                            <b>{{ renewableUploadedFiles.amla_cor.date_expiry }}</b>
                        </v-col>
                        <v-col :cols="3" align="center" v-else>
                            {{ renewableUploadedFiles.amla_cor.date_expiry }}
                        </v-col>

                        <v-col :cols="1" v-if="renewableUploadedFiles.amla_cor">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.amla_cor)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col :cols="1" v-else>
                            No File
                        </v-col>

                        <v-col :cols="2"
                            v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.single_proprietor.amla_cor.file"
                                label="Update"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                        <v-col :cols="2"
                            v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.single_proprietor.amla_cor.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.single_proprietor.amla_cor.date_issued"
                                        label="Issued"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.single_proprietor.amla_cor.file  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.single_proprietor.amla_cor.date_issued"
                                    @input="documents.single_proprietor.amla_cor.menuIssued = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :cols="2"
                                v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.single_proprietor.amla_cor.menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.single_proprietor.amla_cor.date_expiry"
                                        label="Expiry"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.single_proprietor.amla_cor.date_issued  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.single_proprietor.amla_cor.date_expiry"
                                    @input="documents.single_proprietor.amla_cor.menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row class="mx-3">
                        
                        <v-col :cols="2">
                            AMLA Questionnaire
                        </v-col>

                        <v-col :cols="3" align="center">
                            N/A
                        </v-col>

                        <v-col :cols="1" v-if="renewableUploadedFiles.amla_questionnaire">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.amla_questionnaire)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col :cols="1" v-else>
                            No File
                        </v-col>

                        <v-col :cols="4"
                            v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.single_proprietor.amla_questionnaire.file"
                                label="Update/Add File"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                    </v-row>

                    <v-row class="mt-2 mx-3">
                        <v-col
                            v-if="(documents.single_proprietor.business_permit.file && documents.single_proprietor.business_permit.date_issued && documents.single_proprietor.business_permit.date_expiry) || (documents.single_proprietor.dti.file && documents.single_proprietor.dti.date_issued && documents.single_proprietor.dti.date_expiry) || (documents.single_proprietor.amla_cor.file && documents.single_proprietor.amla_cor.date_issued && documents.single_proprietor.amla_cor.date_expiry) || documents.single_proprietor.amla_questionnaire.file">
                            <v-btn outlined color="primary" style="float:right;" @click="uploadDocuments">
                                Update Documents
                            </v-btn>
                        </v-col>
                        <v-col
                            v-else>
                            <v-btn outlined color="primary" style="float:right;" @click="uploadDocuments" disabled>
                                Update Documents
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-expansion-panel-content>

                <v-expansion-panel-content v-if="renewableUploadedFiles.type == 'Cooperative/Hybrid'">
                    <v-row class="mx-3">
                        <v-col :cols="2">
                            <b>File</b>
                        </v-col>
                        <v-col :cols="3" align="center">
                            <b>Expiry Date</b>
                        </v-col>
                    </v-row>
                    
                    <v-row class="mx-3 mt-3"
                    v-if="renewableUploadedFiles.business_permit"
                    >
                        <v-col :cols="2" class="red--text"
                            v-if="renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary"
                        >
                            <b>Business Permit</b>
                        </v-col>
                        <v-col :cols="2"
                            v-else
                        >
                            Business Permit
                        </v-col>

                        <v-col :cols="3" align="center" class="red--text"
                            v-if="renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary"
                        > 
                            <b>{{ renewableUploadedFiles.business_permit.date_expiry }}</b>
                        </v-col>
                        <v-col :cols="3" align="center"
                            v-else
                        > 
                            {{ renewableUploadedFiles.business_permit.date_expiry }}
                        </v-col>

                        <v-col :cols="1">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.business_permit)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col :cols="2"
                            v-if="(renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.cooperative_hybrid.business_permit.file"
                                label="Update"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                        <v-col :cols="2"
                            v-if="(renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.cooperative_hybrid.business_permit.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.cooperative_hybrid.business_permit.date_issued"
                                        label="Issued"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.cooperative_hybrid.business_permit.file  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.cooperative_hybrid.business_permit.date_issued"
                                    @input="documents.cooperative_hybrid.business_permit.menuIssued = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :cols="2"
                                v-if="(renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.cooperative_hybrid.business_permit.menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.cooperative_hybrid.business_permit.date_expiry"
                                        label="Expiry"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.cooperative_hybrid.business_permit.date_issued  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.cooperative_hybrid.business_permit.date_expiry"
                                    @input="documents.cooperative_hybrid.business_permit.menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row class="mx-3 mt-3"
                    v-if="renewableUploadedFiles.cda"
                    >
                        <v-col :cols="2">
                            CDA
                        </v-col>
                        <v-col :cols="3" align="center"></v-col>
                        <v-col :cols="1">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.cda)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col :cols="6" v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.cooperative_hybrid.cda.file"
                                label="Update CDA"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                    </v-row>

                    <v-row class="mx-3">
                        
                        <v-col :cols="2" v-if="!renewableUploadedFiles.amla_cor">
                            AMLA COR
                        </v-col>
                        <v-col :cols="2" class="red--text" v-else-if="renewableUploadedFiles.amla_cor.is_primary || renewableUploadedFiles.amla_cor.is_secondary">
                            <b>AMLA COR</b>
                        </v-col>
                        <v-col :cols="2" v-else>
                            AMLA COR
                        </v-col>

                        <v-col :cols="3" align="center" v-if="!renewableUploadedFiles.amla_cor">
                            No File
                        </v-col>
                        <v-col :cols="3" align="center" class="red--text" v-else-if="renewableUploadedFiles.amla_cor.is_primary || renewableUploadedFiles.amla_cor.is_secondary">
                            <b>{{ renewableUploadedFiles.amla_cor.date_expiry }}</b>
                        </v-col>
                        <v-col :cols="3" align="center" v-else>
                            {{ renewableUploadedFiles.amla_cor.date_expiry }}
                        </v-col>

                        <v-col :cols="1" v-if="renewableUploadedFiles.amla_cor">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.amla_cor)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col :cols="1" v-else>
                            No File
                        </v-col>

                        <v-col :cols="2"
                            v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.cooperative_hybrid.amla_cor.file"
                                label="Update"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                        <v-col :cols="2"
                            v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.cooperative_hybrid.amla_cor.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.cooperative_hybrid.amla_cor.date_issued"
                                        label="Issued"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.cooperative_hybrid.amla_cor.file  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.cooperative_hybrid.amla_cor.date_issued"
                                    @input="documents.cooperative_hybrid.amla_cor.menuIssued = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :cols="2"
                                v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.cooperative_hybrid.amla_cor.menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.cooperative_hybrid.amla_cor.date_expiry"
                                        label="Expiry"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.cooperative_hybrid.amla_cor.date_issued  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.cooperative_hybrid.amla_cor.date_expiry"
                                    @input="documents.cooperative_hybrid.amla_cor.menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row class="mx-3">
                        
                        <v-col :cols="2">
                            AMLA Questionnaire
                        </v-col>

                        <v-col :cols="3" align="center">
                            N/A
                        </v-col>

                        <v-col :cols="1" v-if="renewableUploadedFiles.amla_questionnaire">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.amla_questionnaire)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col v-else :cols="1">
                            No File
                        </v-col>

                        <v-col :cols="4"
                            v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.cooperative_hybrid.amla_questionnaire.file"
                                label="Update/Add File"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                    </v-row>

                    <v-row class="mt-2 mx-3">
                        <v-col
                            v-if="documents.cooperative_hybrid.cda.file || (documents.cooperative_hybrid.business_permit.file && documents.cooperative_hybrid.business_permit.date_issued && documents.cooperative_hybrid.business_permit.date_expiry) || (documents.cooperative_hybrid.amla_cor.file && documents.cooperative_hybrid.amla_cor.date_issued && documents.cooperative_hybrid.amla_cor.date_expiry) || documents.cooperative_hybrid.amla_questionnaire.file">
                            <v-btn outlined color="primary" style="float:right;" @click="uploadDocuments">
                                Update Documents
                            </v-btn>
                        </v-col>
                        <v-col
                            v-else>
                            <v-btn outlined color="primary" style="float:right;" @click="uploadDocuments" disabled>
                                Update Documents
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>

                <v-expansion-panel-content v-if="renewableUploadedFiles.type == 'Corporation'">
                    <v-row class="mx-3">
                        <v-col :cols="2">
                            <b>File</b>
                        </v-col>
                        <v-col :cols="3" align="center">
                            <b>Expiry Date</b>
                        </v-col>
                    </v-row>
                    
                    <v-row class="mx-3 mt-3"
                    v-if="renewableUploadedFiles.business_permit"
                    >
                        <v-col :cols="2" class="red--text"
                            v-if="renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary"
                        >
                            <b>Business Permit</b>
                        </v-col>
                        <v-col :cols="2"
                            v-else
                        >
                            Business Permit
                        </v-col>

                        <v-col :cols="3" align="center" class="red--text"
                            v-if="renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary"
                        >
                            <b>{{ renewableUploadedFiles.business_permit.date_expiry }}</b>
                        </v-col>
                        <v-col :cols="3" align="center"
                        v-else
                        >
                            {{ renewableUploadedFiles.business_permit.date_expiry }}
                        </v-col>

                        <v-col :cols="1">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.business_permit)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col :cols="2"
                            v-if="(renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.corporation.business_permit.file"
                                label="Update"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                        <v-col :cols="2"
                            v-if="(renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.corporation.business_permit.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.corporation.business_permit.date_issued"
                                        label="Issued"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.corporation.business_permit.file  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.corporation.business_permit.date_issued"
                                    @input="documents.corporation.business_permit.menuIssued = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :cols="2"
                                v-if="(renewableUploadedFiles.business_permit.is_primary || renewableUploadedFiles.business_permit.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.corporation.business_permit.menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.corporation.business_permit.date_expiry"
                                        label="Expiry"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.corporation.business_permit.date_issued  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.corporation.business_permit.date_expiry"
                                    @input="documents.corporation.business_permit.menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                


                    <v-row class="mx-3"
                    v-if="renewableUploadedFiles.gis"
                    >
                        <v-col :cols="2" class="red--text"
                            v-if="renewableUploadedFiles.gis.is_primary || renewableUploadedFiles.gis.is_secondary">
                        
                            <b>GIS</b>
                        </v-col>
                        <v-col :cols="2"
                            v-else
                        >
                            GIS
                        </v-col>
                        <v-col :cols="3" align="center" class="red--text"
                            v-if="renewableUploadedFiles.gis.is_primary || renewableUploadedFiles.gis.is_secondary">
                        
                            <b>{{ renewableUploadedFiles.gis.date_expiry }}</b>
                        </v-col>
                        <v-col :cols="3" align="center"
                            v-else
                        >
                            {{ renewableUploadedFiles.gis.date_expiry }}
                        </v-col>

                        <v-col :cols="1">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.gis)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col :cols="2"
                            v-if="(renewableUploadedFiles.gis.is_primary || renewableUploadedFiles.gis.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.corporation.gis.file"
                                label="Update"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                        <v-col :cols="2"
                            v-if="(renewableUploadedFiles.gis.is_primary || renewableUploadedFiles.gis.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.corporation.gis.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.corporation.gis.date_issued"
                                        label="Issued"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.corporation.gis.file  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.corporation.gis.date_issued"
                                    @input="documents.corporation.gis.menuIssued = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :cols="2"
                                v-if="(renewableUploadedFiles.gis.is_primary || renewableUploadedFiles.gis.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.corporation.gis.menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.corporation.gis.date_expiry"
                                        label="Expiry"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.corporation.gis.date_issued  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.corporation.gis.date_expiry"
                                    @input="documents.corporation.gis.menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                  
                    <v-row class="mx-3">
                        
                        <v-col :cols="2" v-if="!renewableUploadedFiles.amla_cor">
                            AMLA COR
                        </v-col>
                        <v-col :cols="2" class="red--text" v-else-if="renewableUploadedFiles.amla_cor.is_primary || renewableUploadedFiles.amla_cor.is_secondary">
                            <b>AMLA COR</b>
                        </v-col>
                        <v-col :cols="2" v-else>
                            AMLA COR
                        </v-col>

                        <v-col :cols="3" align="center" v-if="!renewableUploadedFiles.amla_cor">
                            No File
                        </v-col>
                        <v-col :cols="3" align="center" class="red--text" v-else-if="renewableUploadedFiles.amla_cor.is_primary || renewableUploadedFiles.amla_cor.is_secondary">
                            <b>{{ renewableUploadedFiles.amla_cor.date_expiry }}</b>
                        </v-col>
                        <v-col :cols="3" align="center" v-else>
                            {{ renewableUploadedFiles.amla_cor.date_expiry }}
                        </v-col>

                        <v-col :cols="1" v-if="renewableUploadedFiles.amla_cor">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.amla_cor)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col :cols="1" v-else>
                            No File
                        </v-col>

                        <v-col :cols="2"
                            v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.corporation.amla_cor.file"
                                label="Update"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                        <v-col :cols="2"
                            v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.corporation.amla_cor.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.corporation.amla_cor.date_issued"
                                        label="Issued"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.corporation.amla_cor.file  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.corporation.amla_cor.date_issued"
                                    @input="documents.corporation.amla_cor.menuIssued = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :cols="2"
                                v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-menu
                                v-model="documents.corporation.amla_cor.menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="documents.corporation.amla_cor.date_expiry"
                                        label="Expiry"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!documents.corporation.amla_cor.date_issued  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="documents.corporation.amla_cor.date_expiry"
                                    @input="documents.corporation.amla_cor.menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-row class="mx-3">
                        
                        <v-col :cols="2">
                            AMLA Questionnaire
                        </v-col>

                        <v-col :cols="3" align="center">
                            N/A
                        </v-col>

                        <v-col :cols="1" v-if="renewableUploadedFiles.amla_questionnaire">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.amla_questionnaire)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>
                        <v-col v-else :cols="1">
                            No File
                        </v-col>

                        <v-col :cols="4"
                            v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                            <v-file-input
                                v-model="documents.corporation.amla_questionnaire.file"
                                label="Update/Add File"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                    </v-row>

                    <v-row class="mx-3 mt-3"
                    v-if="renewableUploadedFiles.sec"
                    >
                        <v-col :cols="2">
                            SEC
                        </v-col>
                        <v-col :cols="3" align="center"></v-col>
                        <v-col :cols="1">
                            <v-icon
                                @click="retrieveUploaded(renewableUploadedFiles.sec)"
                                >
                                mdi-eye
                            </v-icon>
                        </v-col>

                        <v-col :cols="6">
                            <v-file-input
                                v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'"
                                v-model="documents.corporation.sec.file"
                                label="Update SEC"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                            </v-file-input>
                        </v-col>
                    </v-row>
                    <v-row class="mt-2 mx-3">
                        <v-col>
                            <v-btn outlined color="primary" style="float:right;" @click="uploadDocuments"
                                v-if="documents.corporation.sec.file || (documents.corporation.business_permit.file && documents.corporation.business_permit.date_issued && documents.corporation.business_permit.date_expiry) || (documents.corporation.gis.file && documents.corporation.gis.date_issued && documents.corporation.gis.date_expiry) || (documents.corporation.amla_cor.file && documents.corporation.amla_cor.date_issued && documents.corporation.amla_cor.date_expiry) || documents.corporation.amla_questionnaire.file">
                                Update Documents
                            </v-btn>
                            <v-btn outlined color="primary" style="float:right;" @click="uploadDocuments" disabled
                                v-else>
                                Update Documents
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>


<script>
import { mapGetters } from 'vuex';
import jsonToFormData from 'json-form-data';
// import _ from 'lodash';
export default {
    computed: {
        ...mapGetters({
            documents: 'salesProspecting/documents',
            renewableUploadedFiles: 'salesProspecting/renewableUploadedFiles',
            currUser: 'auth/currUser',
        })
    },
    async created () {
        const resUpload = await this.$store.dispatch('salesProspecting/dogetUploadedRenewableFiles', this.$route.params.id)
        this.$store.commit('salesProspecting/SET_RENEWABLE_FILES', resUpload.data.result.location_upload[0])
        // console.log('Uploaded FIles')
        console.log(this.renewableUploadedFiles)
    },
    methods: {
		async uploadDocuments () {
            var initialPayload = ''
            if (this.renewableUploadedFiles.type === 'Single Proprietor') {
                initialPayload = this.documents['single_proprietor']
            } else if (this.renewableUploadedFiles.type === 'Cooperative/Hybrid') {
                initialPayload = this.documents['cooperative_hybrid']
            } else if (this.renewableUploadedFiles.type === 'Corporation') {
                initialPayload = this.documents['corporation']
            }
            var options = {
                initialFormData: new FormData(),
                howLeafArrayIndexes: true,
                includeNullValues: false,
                mapping: function(value) {
                    if (typeof value === 'boolean') {
                        return +value ? '1': '0';
                    }
                    return value;
                }
            };
            var convertedFormData = jsonToFormData(initialPayload, options)
            const payload = {id: this.$route.params.id, payload: convertedFormData}
            this.callUploadDocuments(payload)

		},
		async callUploadDocuments(payload){
			const res = await this.$store.dispatch('salesProspecting/doUploadFiles', {payload})
			console.log('here')
			console.log(res)
			this.reloadUploads()
		},
        decodeResUpload (resUpload) {
			// console.log(resUpload)
			if (resUpload.length > 0) {
				var type = resUpload[0].type
				this.businessType = type
				if (type === 'Single Proprietor') {
					resUpload[0].dti = JSON.parse(resUpload[0].dti)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].moa = JSON.parse(resUpload[0].moa)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
				} else if (type === 'Cooperative/Hybrid') {
					resUpload[0].cda = JSON.parse(resUpload[0].cda)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].secretary_certificate = JSON.parse(resUpload[0].secretary_certificate)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].org_chart = JSON.parse(resUpload[0].org_chart)
					resUpload[0].board_resolution = JSON.parse(resUpload[0].board_resolution)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].moa = JSON.parse(resUpload[0].moa)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
				} else if (type === 'Corporation') {
					resUpload[0].sec = JSON.parse(resUpload[0].sec)
					resUpload[0].business_permit = JSON.parse(resUpload[0].business_permit)
					resUpload[0].gis = JSON.parse(resUpload[0].gis)
					resUpload[0].cmap = JSON.parse(resUpload[0].cmap)
					resUpload[0].mother_of_contract = JSON.parse(resUpload[0].mother_of_contract)
					resUpload[0].secretary_certificate = JSON.parse(resUpload[0].secretary_certificate)
					resUpload[0].bir_cor = JSON.parse(resUpload[0].bir_cor)
					resUpload[0].supplier_reference = JSON.parse(resUpload[0].supplier_reference)
					resUpload[0].org_chart = JSON.parse(resUpload[0].org_chart)
					resUpload[0].board_resolution = JSON.parse(resUpload[0].board_resolution)
					resUpload[0].fs_itr = JSON.parse(resUpload[0].fs_itr)
					resUpload[0].moa = JSON.parse(resUpload[0].moa)
					resUpload[0].fss_deed_of_undertaking = JSON.parse(resUpload[0].fss_deed_of_undertaking)
					resUpload[0].fss_pdaf = JSON.parse(resUpload[0].fss_pdaf)
				}
				this.$store.commit('salesProspecting/SET_RENEWABLE_FILES', resUpload[0])
			} else {
				this.$store.commit('salesProspecting/SET_EXISTINGFILES_DEFAULT')
			}
		},
        async reloadUploads () {
			const res = await this.$store.dispatch('salesProspecting/dogetUploadedRenewableFiles', this.$route.params.id)
			this.decodeResUpload(res.data.result.location_upload[0])
            this.$store.commit('salesProspecting/SET_RENEWABLE_FILES', res.data.result.location_upload[0])
            this.$store.commit('salesProspecting/SET_DOCUMENTS_DEFAULT')
		},
        async retrieveUploaded (item) {
            const payload = {
                file:item.url
            }
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
    }
}
 
</script>