<template>
    <div>
        <v-row class="mx-3 mt-3">
            <v-col :cols="2">
                <b>File</b>
            </v-col>
        </v-row>
        <v-row class="mx-3 mt-3"
        >
            <v-col :cols="2">
                BSP License
            </v-col>
            <v-col :cols="3" align="center"></v-col>
            <v-col :cols="1" v-if="renewableUploadedFiles.bsp_license">
                <v-icon
                    @click="retrieveUploaded(renewableUploadedFiles.bsp_license)"
                    >
                    mdi-eye
                </v-icon>
            </v-col>
            <v-col :cols="6" v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                <v-file-input
                    v-model="bsp.bsp_license.file"
                    label="Update BSP"
                    prepend-icon="mdi-paperclip"
                    dense
                    outlined
                    hide-details="auto"
                    >
                </v-file-input>
            </v-col>
        </v-row>
        <v-row class="mt-2 mx-3">
            <v-col v-if="currUser.user_role != 'approver' && currUser.user_role != 'admin'">
                <v-btn outlined color="primary" style="float:right;" @click="uploadDocuments">
                    Update Documents
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import { mapGetters } from 'vuex';
import jsonToFormData from 'json-form-data';
// import _ from 'lodash';
export default {
    computed: {
        ...mapGetters({
            currUser: 'auth/currUser',
            documents: 'salesProspecting/documents',
            renewableUploadedFiles: 'salesProspecting/renewableUploadedFiles',
            bsp : 'salesProspecting/bsp',
        })
    },
    async created () {
        const resUpload = await this.$store.dispatch('salesProspecting/dogetUploadedRenewableFiles', this.$route.params.id)
        this.$store.commit('salesProspecting/SET_RENEWABLE_FILES', resUpload.data.result.location_upload[0])
        console.log(this.renewableUploadedFiles)
    },
    methods: {
		async uploadDocuments () {
            var initialPayload = this.bsp
            var options = {
                initialFormData: new FormData(),
                howLeafArrayIndexes: true,
                includeNullValues: false,
                mapping: function(value) {
                    if (typeof value === 'boolean') {
                        return +value ? '1': '0';
                    }
                    return value;
                }
            };
            var convertedFormData = jsonToFormData(initialPayload, options)
            const payload = {id: this.$route.params.id, payload: convertedFormData}
            this.callUpdateBsp(payload)
		},
		async callUpdateBsp(payload){
			const res = await this.$store.dispatch('salesProspecting/doUploadBSP', {payload})
			console.log('here')
			console.log(res)
			this.reloadUploads()
		},
        async reloadUploads () {
			const res = await this.$store.dispatch('salesProspecting/dogetUploadedRenewableFiles', this.$route.params.id)
			// this.decodeResUpload(res.data.result.location_upload[0])
            this.$store.commit('salesProspecting/SET_RENEWABLE_FILES', res.data.result.location_upload[0])
            this.$store.commit('salesProspecting/SET_DOCUMENTS_DEFAULT')
            this.$store.commit('salesProspecting/SET_BSP_DEFAULT')
		},
        async retrieveUploaded (item) {
            const payload = {
                file:item.url
            }
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
    }
}
 
</script>