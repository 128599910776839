<template>
    <v-expansion-panels>
        <v-expansion-panel v-for="(parentItem, parentIndex) in renewableUploadedOwnerAmla" :key="parentIndex">
            <v-expansion-panel-header>
                    Owner {{parentIndex+1}}
            </v-expansion-panel-header>
         <v-expansion-panel-content eager>

            <v-row class="mx-3">
                <v-col :cols="2">
                    <b>File</b>
                </v-col>
                <v-col :cols="3" align="center">
                    <b>Expiry Date</b>
                </v-col>
            </v-row>

            <v-row class="mx-3"
                v-if="renewableUploadedOwnerAmla[parentIndex].amla"
                >
                <v-col :cols="2" class="red--text"
                    v-if="renewableUploadedOwnerAmla[parentIndex].amla.is_primary || renewableUploadedOwnerAmla[parentIndex].amla.is_secondary"
                >
                    <b>AMLA Certificate</b>
                </v-col>
                <v-col :cols="2"
                    v-else
                >
                    AMLA Certificate
                </v-col>
                <v-col :cols="3" align="center" class="red--text"
                    v-if="renewableUploadedOwnerAmla[parentIndex].amla.is_primary || renewableUploadedOwnerAmla[parentIndex].amla.is_secondary"
                >
                    <b>{{ renewableUploadedOwnerAmla[parentIndex].amla.date_expiry }}</b>
                </v-col>
                <v-col :cols="3" align="center"
                    v-else
                >
                    {{ renewableUploadedOwnerAmla[parentIndex].amla.date_expiry }}
                </v-col>

                <v-col :cols="1">
                    <v-icon
                        @click="retrieveUploaded(renewableUploadedOwnerAmla[parentIndex].amla)"
                        >
                        mdi-eye
                    </v-icon>
                </v-col>
                <v-col :cols="6" align="center"
                v-if="(renewableUploadedOwnerAmla[parentIndex].amla.is_primary || renewableUploadedOwnerAmla[parentIndex].amla.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'"
                >
                    <b class="red--text">Update Owner AMLA </b>
                    <v-btn
                        @click="generateDialog(parentItem.id)"
                        color="primary"
                        text
                        small
                        >
                        <v-icon>mdi-file</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
         </v-expansion-panel-content>
        </v-expansion-panel>
        <OwnerAmlaDialog/>
    </v-expansion-panels>
    
</template>
<script>
import { mapGetters } from 'vuex';
import OwnerAmlaDialog from '@/components/modules/salesProspecting/OwnerAmlaDialog.vue';

export default {
    components:{
        OwnerAmlaDialog: OwnerAmlaDialog
    },
    computed: {
        ...mapGetters({
            currUser: 'auth/currUser',
            renewableUploadedOwnerAmla: 'salesProspecting/renewableUploadedOwnerAmla',
        })
    },
    async created () {
        await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
        const resUpload = await this.$store.dispatch('salesProspecting/dogetUploadedRenewableFiles', this.$route.params.id)
        console.log(resUpload)
        console.log(resUpload.data.result.owner_amla)
        this.$store.commit('salesProspecting/SET_RENEWABLE_OWNER_AMLA', resUpload.data.result.owner_amla)
        console.log('Uploaded FIles')
        console.log(this.renewableUploadedOwnerAmla)
    },
    methods: {
        generateDialog(id){
            console.log(id)
            this.$store.commit('salesProspecting/SET_ID_OWNER_AMLA', id)
            this.$store.commit('salesProspecting/SET_OWNER_AMLADIALOG', true)
        },
        async retrieveUploaded (item) {
            const payload = {
                file:item.url
            }
            const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
            var extension = res.data.result.extension
            var file = Buffer.from(res.data.result.file, 'base64')
            var blob = ''
            if (extension === 'pdf') {
                blob = new Blob([file], { type: 'application/pdf' })
            } else if (extension === 'jpg') {
                blob = new Blob([file], { type: 'image/jpg' })
            } else if (extension === 'png') {
                blob = new Blob([file], { type: 'image/png' })
            } else if (extension === 'jpeg') {
                blob = new Blob([file], { type: 'image/jpeg' })
            }
            var fileURL = URL.createObjectURL(blob)
            window.open(fileURL, '_blank')
        },
    }
}
</script>