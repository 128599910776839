<template>
    <v-expansion-panels>
        <v-expansion-panel v-for="(parentItem, parentIndex) in renewableUploadedBranch" :key="parentIndex">
            <v-expansion-panel-header>
                    Branch {{parentIndex+1}}
            </v-expansion-panel-header>
         <v-expansion-panel-content eager>

            <v-row class="mx-3">
                <v-col :cols="2">
                    <b>File</b>
                </v-col>
                <v-col :cols="3" align="center">
                    <b>Expiry Date</b>
                </v-col>
            </v-row>

            <v-row class="mx-3"
                v-if="renewableUploadedBranch[parentIndex].business_permit"
                >

                <v-col :cols="2" class="red--text"
                    v-if="renewableUploadedBranch[parentIndex].business_permit.is_primary || renewableUploadedBranch[parentIndex].business_permit.is_secondary"
                >
                    <b>Business Permit</b>
                </v-col>
                <v-col :cols="2"
                v-else
                >
                    Business Permit
                </v-col>

                <v-col :cols="3" align="center" class="red--text"
                    v-if="renewableUploadedBranch[parentIndex].business_permit.is_primary || renewableUploadedBranch[parentIndex].business_permit.is_secondary"
                >
                    <b>{{ renewableUploadedBranch[parentIndex].business_permit.date_expiry }}</b>
                </v-col>
                <v-col :cols="3" align="center"
                    v-else
                >
                    {{ renewableUploadedBranch[parentIndex].business_permit.date_expiry }}
                </v-col>

                <v-col :cols="1">
                    <v-icon
                        @click="retrieveUploaded(renewableUploadedBranch[parentIndex].business_permit)"
                        >
                        mdi-eye
                    </v-icon>
                </v-col>
                <v-col :cols="6" align="center"
                    v-if="(renewableUploadedBranch[parentIndex].business_permit.is_primary || renewableUploadedBranch[parentIndex].business_permit.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'"
                >
                    <b class="red--text">Update Business Permit </b>
                    <v-btn
                        @click="generateDialog(parentItem.id)"
                        color="primary"
                        text
                        small
                        >
                        <v-icon>mdi-file</v-icon>
                    </v-btn>
                </v-col>
            </v-row>

            <v-row>
                <v-expansion-panels v-if="renewableUploadedBranch[parentIndex].fla_amla">
                    <v-expansion-panel v-for="(item, index) in renewableUploadedBranch[parentIndex].fla_amla" :key="index" >

                        <section v-if="renewableUploadedBranch[parentIndex].fla_amla[index].amla"> 

                            <v-expansion-panel-header class="red--text"
                            v-if="renewableUploadedBranch[parentIndex].fla_amla[index].amla.is_primary || renewableUploadedBranch[parentIndex].fla_amla[index].amla.is_secondary"
                            >
                                    <b>FLA {{index+1}} (AMLA)</b>
                            </v-expansion-panel-header>
                            <v-expansion-panel-header
                                v-else
                            >
                                    FLA {{index+1}} (AMLA)
                            </v-expansion-panel-header>

                            <v-expansion-panel-content eager>

                                <v-row class="mx-3"
                                    v-if="renewableUploadedBranch[parentIndex].fla_amla[index].amla"
                                    >
                                    <v-col :cols="2" class="red--text"
                                        v-if="renewableUploadedBranch[parentIndex].fla_amla[index].amla.is_primary || renewableUploadedBranch[parentIndex].fla_amla[index].amla.is_secondary"
                                    >
                                        <b>AMLA Certificate</b>
                                    </v-col>
                                    <v-col :cols="2"
                                        v-else
                                    >
                                        AMLA Certificate
                                    </v-col>

                                    <v-col :cols="3" align="center" class="red--text"
                                        v-if="renewableUploadedBranch[parentIndex].fla_amla[index].amla.is_primary || renewableUploadedBranch[parentIndex].fla_amla[index].amla.is_secondary"
                                    >
                                        <b>{{ renewableUploadedBranch[parentIndex].fla_amla[index].amla.date_expiry }}</b>
                                    </v-col>
                                    <v-col :cols="3" align="center"
                                        v-else
                                    >
                                        {{ renewableUploadedBranch[parentIndex].fla_amla[index].amla.date_expiry }}
                                    </v-col>

                                    <v-col :cols="1">
                                        <v-icon
                                            @click="retrieveUploaded(renewableUploadedBranch[parentIndex].fla_amla[index].amla)"
                                            >
                                            mdi-eye
                                        </v-icon>
                                    </v-col>
                                    <v-col :cols="6" align="center"
                                    v-if="(renewableUploadedBranch[parentIndex].fla_amla[index].amla.is_primary || renewableUploadedBranch[parentIndex].fla_amla[index].amla.is_secondary) && currUser.user_role != 'approver' && currUser.user_role != 'admin'"
                                    >
                                        <b class="red--text">Update Branch AMLA </b>
                                        <v-btn
                                            @click="generateAmlaDialog(item.id)"
                                            color="primary"
                                            text
                                            small
                                            >
                                            <v-icon>mdi-file</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>

                            </v-expansion-panel-content>

                        </section>

                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
         </v-expansion-panel-content>
        </v-expansion-panel>
        <BusinessPermitDialog/>
        <FlaDialog/>
    </v-expansion-panels>
    
</template>
<script>

import { mapGetters } from 'vuex';
import BusinessPermitDialog from '@/components/modules/salesProspecting/BusinessPermitDialog.vue';
import FlaDialog from '@/components/modules/salesProspecting/FlaDialog.vue';
import _ from 'lodash';
export default {
    components:{
        BusinessPermitDialog: BusinessPermitDialog,
        FlaDialog: FlaDialog
    },
    computed: {
        ...mapGetters({
            branches: 'salesProspecting/branches',
            renewableUploadedBranch: 'salesProspecting/renewableUploadedBranch',
            renewableUploadedBranchAmla: 'salesProspecting/renewableUploadedBranchAmla',
            existingUploadedBranches: 'salesProspecting/existingUploadedBranches',
            branchDialog: 'salesProspecting/branchDialog',
            branch_business_permit: 'salesProspecting/branch_business_permit',
            currUser: 'auth/currUser',
        })
    },
    async created () {
        const resSP = await this.$store.dispatch('salesProspecting/doGetSalesProspecting', this.$route.params.id)
        this.decodeBranches(resSP.data.result[0].sp_branch.data)
        const resUpload = await this.$store.dispatch('salesProspecting/dogetUploadedRenewableFiles', this.$route.params.id)
        console.log(resUpload)
        this.$store.commit('salesProspecting/SET_RENEWABLE_BRANCH_PERMIT', resUpload.data.result.branch_business_permit)
        console.log('Uploaded FIles')
        console.log(this.renewableUploadedBranch)
    },
    methods: {
        generateDialog(id){
            console.log(id)
            this.$store.commit('salesProspecting/SET_ID_BRANCH_PERMIT', id)
            this.$store.commit('salesProspecting/SET_BRANCHDIALOG', true)
        },
        generateAmlaDialog(id){
            console.log(id)
            this.$store.commit('salesProspecting/SET_ID_AMLA', id)
            this.$store.commit('salesProspecting/SET_AMLADIALOG', true)
        },
        updatePermit(index, item){
            console.log(index)
            console.log(item)
            const initialPayload = {
                id: item.id,
                file: this.branches.business_permit.file,
                date_issued: this.branches.business_permit.date_issued,
                date_expiry: this.branches.business_permit.date_expiry

            }
            console.log(initialPayload)
        },
        decodeBranches (resBranches) {
			let expiryFile = {
                file: null,
                date_issued: '',
                date_expiry: '',
                menuIssued: false,
                menuExpiry: false
            }
			let nonExpiryFile = {
                file: null
            }
			resBranches.forEach(function (e) {
				// console.log(e.business_permit)
				e.branch_id = e.id
				e.businessPermitUploaded = JSON.parse(e.business_permit)
				e.justificationUploaded = JSON.parse(e.justification)
				// console.log(e.businessPermitUploaded)
				e.operating_hours = JSON.parse(e.operating_hours)
				e.operating_hours.forEach(function (g) {
					g.isClosed = parseInt(g.isClosed)
				})
				e.coordinates = JSON.parse(e.coordinates)
				e.business_permit = _.clone(expiryFile)
				e.justification = _.clone(nonExpiryFile)
				e.fla.forEach(function (f) {
					f.amlaUploaded  = JSON.parse(f.amla)
					f.drugTestUploaded = JSON.parse(f.drug_test)
					f.nbiUploaded = JSON.parse(f.nbi)
					f.amla = _.clone(expiryFile)
					f.drug_test = _.clone(expiryFile)
					f.nbi = _.clone(nonExpiryFile)
				})
			})
			this.$store.commit('salesProspecting/SET_EXISTING_UPLOADED_BRANCHES', resBranches)
			this.existingUploadedBranches.map(coords => {
                coords.coordinates.lat = parseFloat(coords.coordinates.lat)
                coords.coordinates.lng = parseFloat(coords.coordinates.lng)    
            })
		},
        async retrieveUploaded (item) {
            const payload = {
                file:item.url
            }
			const res = await this.$store.dispatch('salesProspecting/doRetrieveUploaded', payload)
			var extension = res.data.result.extension
			var file = Buffer.from(res.data.result.file, 'base64')
			var blob = ''
			if (extension === 'pdf') {
				blob = new Blob([file], { type: 'application/pdf' })
			} else if (extension === 'jpg') {
				blob = new Blob([file], { type: 'image/jpg' })
			} else if (extension === 'png') {
				blob = new Blob([file], { type: 'image/png' })
			} else if (extension === 'jpeg') {
				blob = new Blob([file], { type: 'image/jpeg' })
			}
			var fileURL = URL.createObjectURL(blob)
			window.open(fileURL, '_blank')
		},
    }
}
</script>
