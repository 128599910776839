<template>
    <v-dialog
      v-model="ownerAmlaDialog"
      width="900px"
      persistent
    >
        <v-card>
            <v-card-title>
                <span class="text-h5" id="dialog_title">Update AMLA</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                <v-row>
                        <v-col :cols="6">
                            <v-file-input
                                v-model="owner_amla.file"
                                label="AMLA"
                                prepend-icon="mdi-paperclip"
                                dense
                                outlined
                                hide-details="auto"
                                >
                                </v-file-input>
                        </v-col>
                        <v-col :cols="3">
                            <v-menu
                                v-model="owner_amla.menuIssued"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                hide-details="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="owner_amla.date_issued"
                                        label="Date Issued"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!owner_amla.file  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        >
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="owner_amla.date_issued"
                                    @input="owner_amla.menuIssued = false"
                                
                                    >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col :cols="3">
                            <v-menu
                                v-model="owner_amla.menuExpiry"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                hide-details="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="owner_amla.date_expiry"
                                        label="Expiry Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="!owner_amla.date_issued  ? '' : on"
                                        dense
                                        outlined
                                        hide-details="auto"
                                        >
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="owner_amla.date_expiry"
                                    @input="owner_amla.menuExpiry = false"
                                    hide-details="auto"
                                    :min="owner_amla.date_issued "
                                    >
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn
                outlined color="primary"
                text
                @click="cancel()"
            >
                Cancel
            </v-btn>

            <v-btn @click="update" color="primary"
                v-if="owner_amla.file && owner_amla.date_expiry && owner_amla.date_issued"
            >
                Update
            </v-btn>

            <v-btn @click="update" color="primary" v-else disabled>
                Update
            </v-btn>
            
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>


import { mapGetters } from 'vuex';
import jsonToFormData from 'json-form-data';
export default {
    computed: {
        ...mapGetters({
            owner_amla: 'salesProspecting/owner_amla',
            ownerAmlaDialog: 'salesProspecting/ownerAmlaDialog',
        })
    },
    methods: {
        cancel(){
            this.owner_amla.file = null,
            this.owner_amla.date_expiry = '',
            this.owner_amla.date_issued = '',
            this.owner_amla.menuIssued = false,
            this.owner_amla.menuExpiry = false,
            this.$store.commit('salesProspecting/SET_OWNER_AMLADIALOG', false)
        },
        async update(){
            const initialPayload = {
                owner_amla: this.owner_amla
            }
            var options = {
                initialFormData: new FormData(),
                howLeafArrayIndexes: true,
                includeNullValues: false,
                mapping: function(value) {
                    if (typeof value === 'boolean') {
                        return +value ? '1': '0';
                    }
                    return value;
                }
            };
            var convertedFormData = jsonToFormData(initialPayload, options)
            console.log(initialPayload)
            const payload = {id: this.$route.params.id, payload: convertedFormData}
            const res = await this.$store.dispatch('salesProspecting/doUpdateRenewableDocs', {payload})
            if(res.status === 200) {
                    this.owner_amla.file = null,
                    this.owner_amla.date_expiry = '',
                    this.owner_amla.date_issued = '',
                    this.$store.commit('salesProspecting/SET_OWNER_AMLADIALOG', false)
                }
            this.reloadUploads()
        },
        async reloadUploads () {
			const res = await this.$store.dispatch('salesProspecting/dogetUploadedRenewableFiles', this.$route.params.id)
            console.log(res)
            this.$store.commit('salesProspecting/SET_RENEWABLE_OWNER_AMLA', res.data.result.owner_amla)
            console.log('here')
		},
    }
}
</script>
